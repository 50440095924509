import { Box, Button, Heading } from "@chakra-ui/react";
import React from "react";

import ReflectionContainer from "./reflection_container";
import ReviewPreviousActionItemsContainer from "./review_previous_action_items_container";

type RetroTemplateType = {
  retroSpaceMembersCount: number;
};

export default function RetroTemplate({
  retroSpaceMembersCount,
}: RetroTemplateType) {
  return (
    <div>
      <Box display="flex" alignItems="center" gap="1em">
        <Heading as="h2" size="md">
          Current Recap:
        </Heading>
        <Button
          variant="outline"
          isDisabled={retroSpaceMembersCount < 2 ? true : false}
        >
          Select Recap Meeting Date
        </Button>
      </Box>
      <ReflectionContainer
        category={"Appreciations"}
        retroSpaceMembersCount={retroSpaceMembersCount}
      />
      <ReviewPreviousActionItemsContainer
        retroSpaceMembersCount={retroSpaceMembersCount}
      />
      <ReflectionContainer
        category={"Discussions/Challenges"}
        retroSpaceMembersCount={retroSpaceMembersCount}
      />
      <ReflectionContainer
        category={"Plan a fun activity"}
        retroSpaceMembersCount={retroSpaceMembersCount}
      />
      <Heading as="h2" size="md">
        Action Items
      </Heading>
    </div>
  );
}
