import { Text } from "@chakra-ui/react";
import React from "react";

export default function StyledAsterisk() {
  return (
    <Text color="darkRed" display="inline-block">
      *
    </Text>
  );
}
