import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const baseStyle = defineStyle({
  baseStyle: {
    color: "darkMoss.600",
    _hover: {
      textDecoration: "underline",
    },
  },
});

export const linkTheme = defineStyleConfig(baseStyle);
