import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Skeleton,
  chakra,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import RichTextArea from "../../library/ui_custom/rich_text_area";
import StyledAsterisk from "../../library/ui_custom/styled_asterisk";
import Form from "../../library/ui_primitives/form";
import useCanUseAiFeatures from "../hooks/use_can_use_ai_features";
import AiCompanionContainer from "./ai_companion_container";
import PrivateJournalEntryDeleteConfirmationModal from "./private_journal_entry_delete_confirmation_modal";
import PrivateJournalEntryEditModal from "./private_journal_entry_edit_modal";
import { PrivateJournalEntryListings } from "./private_journal_entry_listings";
import { ActionItem } from "./types";

export type FormValues = {
  title: string;
  content: string;
  actionItems: ActionItem[];
};

const StyledDescription = chakra("p", {
  baseStyle: {
    marginTop: "2em",
    marginBottom: "2em",
    backgroundColor: "core.apple",
    padding: "1em",
    borderRadius: "1em",
  },
});

const StyledFormControl = chakra(FormControl, {
  baseStyle: {
    marginBottom: "1em",
  },
});

export default function PrivateJournal() {
  const toast = useToast();
  const queryClient = useQueryClient();
  const [
    deletePrivateJournalEntryConfirmationId,
    setDeletePrivateJournalEntryConfirmationId,
  ] = useState<number | undefined>(undefined);

  const { canUseAiFeatures } = useCanUseAiFeatures();

  const [inputText, setInputText] = useState<string | undefined>();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    reset: resetForm,
  } = useForm<FormValues>();

  useEffect(() => {
    if (errors.content) {
      toast({
        title: "Error: Please make sure all required fields are filled out.",
        status: "error",
      });
    }
  }, [errors.content]);

  const mutation = useMutation(async (data: FormValues) => {
    const res = await axios.post("/api/user_app/private_journal_entries", {
      ...data,
    });

    if (res.data.privateJournalEntryId) {
      queryClient.invalidateQueries(["privateJournalEntries"]);
      resetForm();
      toast({
        title: "Success: Note saved! 🎉",
        status: "success",
      });
    } else {
      setError("title", {
        type: "server",
        message: res.data.error,
      });
      toast({
        title: res.data.errors.join(","),
        status: "error",
      });
    }
  });

  return (
    <div>
      <PrivateJournalEntryEditModal canUseAiFeatures={!!canUseAiFeatures} />
      <PrivateJournalEntryDeleteConfirmationModal
        privateJournalEntryId={deletePrivateJournalEntryConfirmationId}
        onCloseComplete={() =>
          setDeletePrivateJournalEntryConfirmationId(undefined)
        }
      />
      <Heading as="h1" size="md">
        Notes to Self
      </Heading>
      <StyledDescription>
        Got some thoughts you want to process or write down privately? This is
        your private space to help you navigate your relationship. As you
        journal you can also add action items. They will show up on your
        homepage for easy access.
      </StyledDescription>

      <Form onSubmit={handleSubmit((data) => mutation.mutateAsync(data))}>
        <Skeleton isLoaded={canUseAiFeatures !== undefined}>
          <Box
            display="flex"
            justifyContent="space-between"
            gap="2em"
            height="35em"
          >
            <Box display="flex" flexDirection="column" flex={2}>
              <StyledFormControl>
                <FormLabel htmlFor="title">Note Title (optional)</FormLabel>
                <Input
                  id="title"
                  type="text"
                  bg="white"
                  autoComplete="off"
                  {...register("title")}
                  borderColor="gray.400"
                />
              </StyledFormControl>

              <FormControl
                display="flex"
                flexDirection="column"
                flex="1"
                isRequired
                isInvalid={!!errors.content}
              >
                <FormLabel
                  htmlFor="content"
                  requiredIndicator={<StyledAsterisk />}
                >
                  Add your thoughts...
                </FormLabel>

                <Controller
                  control={control}
                  name="content"
                  rules={{
                    required: true,
                  }}
                  defaultValue={""}
                  render={({ field, fieldState }) => {
                    return (
                      <RichTextArea
                        editable={true}
                        initialEditableContainer={true}
                        value={field.value}
                        onChange={(event) => {
                          setInputText(field.value);
                          field.onChange(event);
                        }}
                        isDirty={fieldState.isDirty}
                      />
                    );
                  }}
                />
                <FormErrorMessage>
                  {errors.content && (
                    <p>
                      Error: Please add some thoughts above in order to save
                      your entry.
                    </p>
                  )}
                </FormErrorMessage>
              </FormControl>
            </Box>

            <AiCompanionContainer
              inputText={inputText}
              canUseAiFeatures={!!canUseAiFeatures}
            />
          </Box>
        </Skeleton>

        {/* TODO: In the future make this dynamic using useFieldArray hook, but hardcoding 3 inputs for now */}
        <FormControl marginTop="2.5em" marginBottom="1em">
          <FormLabel htmlFor="actionItem1">Action Item #1 (optional)</FormLabel>
          <Input
            borderColor="gray.400"
            id="actionItem1"
            type="text"
            autoComplete="off"
            {...register("actionItems.0")}
          />
        </FormControl>

        <StyledFormControl>
          <FormLabel htmlFor="actionItem2">Action Item #2 (optional)</FormLabel>
          <Input
            borderColor="gray.400"
            id="actionItem2"
            type="text"
            autoComplete="off"
            {...register("actionItems.1")}
          />
        </StyledFormControl>

        <StyledFormControl>
          <FormLabel htmlFor="actionItem3">Action Item #3 (optional)</FormLabel>
          <Input
            borderColor="gray.400"
            id="actionItem3"
            type="text"
            autoComplete="off"
            {...register("actionItems.2")}
          />
        </StyledFormControl>

        <Button
          marginBottom="2em"
          isLoading={isSubmitting}
          type="submit"
          data-umami-event="create-new-journal-note-btn"
        >
          Save
        </Button>
      </Form>

      <PrivateJournalEntryListings
        setDeletePrivateJournalEntryConfirmationId={
          setDeletePrivateJournalEntryConfirmationId
        }
      />
    </div>
  );
}
