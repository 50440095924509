import { Box, Text } from "@chakra-ui/react";
import React from "react";

export default function Summarization({
  summaryText,
}: {
  summaryText: string;
}) {
  return (
    <Box marginBottom="2em">
      <Text fontWeight="500">AI Summary:</Text>
      <p>{summaryText}</p>
    </Box>
  );
}
