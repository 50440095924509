import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const baseStyle = defineStyle({
  defaultProps: {
    colorScheme: "darkMoss",
  },
  baseStyle: {
    _focus: {
      border: "solid 3px",
    },
  },
});

export const buttonTheme = defineStyleConfig(baseStyle);
