import { Input as ChakraInput } from "@chakra-ui/react";
import React from "react";

type InputProps = React.ComponentProps<typeof ChakraInput>;

const Input = React.forwardRef(
  (props: InputProps, ref: React.LegacyRef<HTMLInputElement>) => {
    return <ChakraInput errorBorderColor="darkRed" ref={ref} {...props} />;
  }
);

export default Input;
