import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect } from "react";

export default function PrivateJournalEntryDeleteConfirmationModal({
  privateJournalEntryId,
  onCloseComplete,
}: {
  privateJournalEntryId: number | undefined;
  onCloseComplete: () => void;
}) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { isOpen, onOpen, onClose: onDisclosureClose } = useDisclosure();
  useEffect(() => {
    if (privateJournalEntryId) {
      onOpen();
    }
  }, [privateJournalEntryId]);

  const { mutateAsync: deletePrivateJournalEntry } = useMutation({
    mutationFn: async (privateJournalEntryId: number) => {
      await axios.delete(
        `/api/user_app/private_journal_entries/${privateJournalEntryId}`
      );
    },
    onSuccess: () => {
      // Refetch the private journal entries to update the UI.
      queryClient.invalidateQueries(["privateJournalEntries"]);
      toast({
        title: "Success: Note has been deleted. 🎉",
        status: "success",
      });
    },
    onError: () => {
      toast({
        title: "An error occurred.",
        description: "Please try again later.",
        status: "error",
      });
    },
  });

  if (!privateJournalEntryId) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onDisclosureClose}
      onCloseComplete={onCloseComplete}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Please Confirm</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p>Are you sure you want to delete this note?</p>
          <ModalFooter>
            <Button
              mr={3}
              onClick={() => {
                deletePrivateJournalEntry(privateJournalEntryId);
                onDisclosureClose();
              }}
            >
              Yes, delete
            </Button>
            <Button
              variant="ghost"
              onClick={() => {
                onDisclosureClose();
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
