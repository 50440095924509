import { chakra, keyframes } from "@chakra-ui/react";
import React from "react";

const loadingFade = keyframes({
  "0%": { opacity: 0 },
  "50%": { opacity: 0.8 },
  "100%": { opacity: 0 },
});

const StyledDot = chakra("div", {
  baseStyle: {
    float: "left",
    width: "0.5em",
    height: "0.5em",
    margin: "0 4px",
    background: "#4b4d4b",
    borderRadius: "50%",
    opacity: 0,
    animation: `${loadingFade} 1s infinite`,
  },
});

const StyledDotsContainer = chakra("div", {
  baseStyle: {
    width: "5em",
    height: "2em",
    position: "relative",
    padding: "0.5em",
    background: "peach.50",
    borderRadius: "1em",
    marginBottom: "1.5em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& > div:nth-of-type(1)": { animationDelay: "0s" },
    "& > div:nth-of-type(2)": { animationDelay: "0.2s" },
    "& > div:nth-of-type(3)": { animationDelay: "0.4s" },
  },
});

export default function TypingIndicator() {
  return (
    <StyledDotsContainer>
      <StyledDot />
      <StyledDot />
      <StyledDot />
    </StyledDotsContainer>
  );
}
