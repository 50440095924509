import {
  Box,
  CardBody,
  CardHeader,
  Checkbox,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  InputGroup,
  InputRightElement,
  Link,
  Radio,
  RadioGroup,
  Stack,
  chakra,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";

import { isValidEmail, passwordRegex } from "../../utility/string_utils";
import StyledAsterisk from "../library/ui_custom/styled_asterisk";
import Form from "../library/ui_primitives/form";
import Input from "../library/ui_primitives/input";
import {
  StyledButton,
  StyledCard,
  StyledFormControl,
  StyledPasswordViewButton,
  StyledText,
} from "../signed_out/shared_components";

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  unitedStatesUser: boolean;
  acceptedPolicies: boolean;
  acceptedEssentialTracking: string;
};

const StyledLink = chakra(Link, {
  baseStyle: { textDecoration: "underline" },
});

function CreateAccountContent() {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const handleShowPasswordClick = () => setShowPassword(!showPassword);
  const handleShowConfirmPasswordClick = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<FormValues>();

  const mutation = useMutation(async (data: FormValues) => {
    if (data.acceptedEssentialTracking === "false") {
      setError("acceptedEssentialTracking", {
        type: "manual",
        message:
          "Rejecting the use of essential cookies will prohibit you from creating an account and accessing the Claribee platform.",
      });
      return;
    }

    const res = await axios.post("/users", { user: data });

    if (res.data.userId) {
      window.location.assign("/user_app/home");
    } else {
      setError("email", {
        type: "server",
        message: res.data.error,
      });
    }
  });

  return (
    <StyledCard>
      <CardHeader textAlign="center">
        <Heading size="lg">Create an Account</Heading>
      </CardHeader>
      <CardBody>
        <StyledText>All fields are required unless otherwise noted.</StyledText>
        <Form onSubmit={handleSubmit((data) => mutation.mutateAsync(data))}>
          <StyledFormControl isRequired isInvalid={!!errors.email}>
            <FormLabel htmlFor="email" requiredIndicator={<StyledAsterisk />}>
              Email
            </FormLabel>{" "}
            <Input
              id="email"
              type="text"
              {...register("email", {
                required: {
                  value: true,
                  message: "Error: Email is required",
                },
                validate: (v) => isValidEmail(v) || "Email is not valid",
              })}
            />
            <FormErrorMessage>
              {errors.email && errors.email.message?.toString()}
            </FormErrorMessage>
          </StyledFormControl>
          <StyledFormControl isRequired isInvalid={!!errors.firstName}>
            <FormLabel
              htmlFor="firstName"
              requiredIndicator={<StyledAsterisk />}
            >
              First Name
            </FormLabel>
            <Input
              id="firstName"
              type="text"
              {...register("firstName", {
                required: {
                  value: true,
                  message: "Error: First Name is required",
                },
              })}
            />
            <FormErrorMessage>
              {errors.firstName && errors.firstName.message?.toString()}
            </FormErrorMessage>
          </StyledFormControl>
          <StyledFormControl isRequired isInvalid={!!errors.lastName}>
            <FormLabel
              htmlFor="lastName"
              requiredIndicator={<StyledAsterisk />}
            >
              Last Name
            </FormLabel>
            <Input
              id="lastName"
              type="text"
              {...register("lastName", {
                required: {
                  value: true,
                  message: "Error: Last Name is required",
                },
              })}
            />
            <FormErrorMessage>
              {errors.lastName && errors.lastName.message?.toString()}
            </FormErrorMessage>
          </StyledFormControl>

          <StyledFormControl isRequired isInvalid={!!errors.password}>
            <FormLabel
              htmlFor="password"
              requiredIndicator={<StyledAsterisk />}
            >
              Password
            </FormLabel>{" "}
            <InputGroup size="md">
              <Input
                id="password"
                type={showPassword ? "text" : "password"}
                {...register("password", {
                  required: {
                    value: true,
                    message: "Error: Password is required",
                  },
                  minLength: {
                    value: 8,
                    message: "Error: Minimum length should be 8",
                  },
                  pattern: {
                    value: passwordRegex,
                    message:
                      "Error: Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
                  },
                })}
                paddingRight="70px" // Cut off text for the right button
              />
              <InputRightElement width="4em">
                <StyledPasswordViewButton
                  variant="outline"
                  size="sm"
                  onClick={handleShowPasswordClick}
                >
                  {showPassword ? "Hide" : "Show"}
                </StyledPasswordViewButton>
              </InputRightElement>
            </InputGroup>
            <FormHelperText>Password must:</FormHelperText>
            <FormHelperText>- be at least 8 characters long</FormHelperText>
            <FormHelperText>
              - consist of uppercase letters and lowercase letters
            </FormHelperText>
            <FormHelperText>- consist of at least one number</FormHelperText>
            <FormHelperText>
              - consist of at least one special character (e.g. !@#$%&*())
            </FormHelperText>
            <FormErrorMessage>
              {errors.password && errors.password.message?.toString()}
            </FormErrorMessage>
          </StyledFormControl>
          <StyledFormControl
            isRequired
            isInvalid={!!errors.passwordConfirmation}
          >
            <Box marginTop="3em">
              <FormLabel
                htmlFor="passwordConfirmation"
                requiredIndicator={<StyledAsterisk />}
              >
                Confirm Password
              </FormLabel>{" "}
              <InputGroup size="md">
                <Input
                  id="passwordConfirmation"
                  type={showConfirmPassword ? "text" : "password"}
                  {...register("passwordConfirmation", {
                    required: {
                      value: true,
                      message: "Error: Password Confirmation is required",
                    },
                    validate: (val: string) => {
                      if (watch("password") != val) {
                        return "Error: Your passwords do not match";
                      }
                    },
                  })}
                  paddingRight="70px" // Cut off text for the right button
                />
                <InputRightElement width="4em">
                  <StyledPasswordViewButton
                    variant="outline"
                    size="sm"
                    onClick={handleShowConfirmPasswordClick}
                  >
                    {showConfirmPassword ? "Hide" : "Show"}
                  </StyledPasswordViewButton>
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>
                {errors.passwordConfirmation &&
                  errors.passwordConfirmation.message?.toString()}
              </FormErrorMessage>
            </Box>
          </StyledFormControl>

          <StyledFormControl isRequired isInvalid={!!errors.unitedStatesUser}>
            <Checkbox
              id="unitedStatesUser"
              borderColor="gray.500"
              isRequired
              {...register("unitedStatesUser", {
                required: {
                  value: true,
                  message:
                    "Error: Confirmation that you are based in the United States is required",
                },
              })}
            >
              By checking this box, I confirm that I am currently based in the
              United States. (Beta is not available outside the U.S.)
              <StyledAsterisk />
            </Checkbox>
            <FormErrorMessage>
              {errors.unitedStatesUser &&
                errors.unitedStatesUser.message?.toString()}
            </FormErrorMessage>
          </StyledFormControl>

          <StyledFormControl isRequired isInvalid={!!errors.acceptedPolicies}>
            <Checkbox
              id="acceptedPolicies"
              borderColor="gray.500"
              isRequired
              {...register("acceptedPolicies", {
                required: {
                  value: true,
                  message:
                    "Error: Acknowledgement that you have read and agree with the policies is required",
                },
              })}
            >
              By checking this box, I acknowledge that I have read and agree to
              the Claribee{" "}
              <StyledLink
                href="https://drive.google.com/file/d/12jHwCWwPzxG1HLbmv4RpTLNzhWvdCshP/view?usp=sharing"
                target="_blank"
                data-umami-event="create-account-terms-and-conditions"
              >
                Terms & Conditions
              </StyledLink>{" "}
              and{" "}
              <StyledLink
                href="https://drive.google.com/file/d/13mXzw5n0TySCFI4fIiJ4myPFDck8oOfi/view?usp=sharing"
                target="_blank"
                data-umami-event="create-account-privacy-policy"
              >
                Privacy Policy
              </StyledLink>
              .<StyledAsterisk />
            </Checkbox>
            <FormErrorMessage>
              {errors.acceptedPolicies &&
                errors.acceptedPolicies.message?.toString()}
            </FormErrorMessage>
          </StyledFormControl>

          <StyledFormControl isInvalid={!!errors.acceptedEssentialTracking}>
            <Box marginTop="3em" backgroundColor="#f7f7f7" padding="1em">
              <Box marginBottom="1em">
                🍪 We use only essential (strictly necessary) cookies for basic
                website functionality (e.g. page navigation, accessing secure
                areas, etc). Details can be found in our{" "}
                <StyledLink
                  href="https://drive.google.com/file/d/13mXzw5n0TySCFI4fIiJ4myPFDck8oOfi/view?usp=sharing"
                  target="_blank"
                  data-umami-event="create-account-privacy-policy"
                >
                  Privacy Policy
                </StyledLink>
                .{" "}
                <strong>
                  Do you accept or reject the use of essential cookies?
                </strong>
                <StyledAsterisk />
              </Box>
              <RadioGroup id="acceptedEssentialTracking">
                <Stack direction="row" spacing="2.5em">
                  <Radio
                    {...register("acceptedEssentialTracking", {
                      required: {
                        value: true,
                        message:
                          "Error: Selection of acceptance or rejection of essential cookies is required",
                      },
                    })}
                    value="true"
                    borderColor="gray.500"
                  >
                    I accept
                  </Radio>
                  <Radio
                    {...register("acceptedEssentialTracking", {
                      required: {
                        value: true,
                        message:
                          "Error: Selection of acceptance or rejection of essential cookies is required",
                      },
                    })}
                    value="false"
                    borderColor="gray.500"
                  >
                    I reject
                  </Radio>
                </Stack>
              </RadioGroup>
            </Box>
            <FormErrorMessage>
              {errors.acceptedEssentialTracking &&
                errors.acceptedEssentialTracking.message?.toString()}
            </FormErrorMessage>
          </StyledFormControl>

          <StyledButton
            isLoading={isSubmitting}
            type="submit"
            data-umami-event="create-account-submit-btn"
          >
            Create Your Account
          </StyledButton>
        </Form>
      </CardBody>
    </StyledCard>
  );
}

export default CreateAccountContent;
