import { Button, Card, FormControl, chakra } from "@chakra-ui/react";

export const StyledCard = chakra(Card, {
  baseStyle: {
    marginTop: "3em",
    backgroundColor: "core.apple",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "3em",
    width: "75%",
  },
});

export const StyledText = chakra("p", {
  baseStyle: { marginBottom: "2em" },
});

export const StyledFormControl = chakra(FormControl, {
  baseStyle: { marginBottom: "1em" },
});

export const StyledButton = chakra(Button, {
  baseStyle: { marginTop: "1em" },
});

export const StyledPasswordViewButton = chakra(Button, {
  baseStyle: { marginRight: "0.5em", paddingRight: "1em" },
});
