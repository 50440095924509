import {
  Box,
  CardBody,
  CardHeader,
  FormErrorMessage,
  FormLabel,
  Heading,
  InputGroup,
  InputRightElement,
  Link,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import { Link as ReactRouterLink } from "react-router-dom";

import { isValidEmail } from "../../utility/string_utils";
import StyledAsterisk from "../library/ui_custom/styled_asterisk";
import Form from "../library/ui_primitives/form";
import Input from "../library/ui_primitives/input";
import {
  StyledButton,
  StyledCard,
  StyledFormControl,
  StyledPasswordViewButton,
  StyledText,
} from "../signed_out/shared_components";

type FormValues = {
  email: string;
  password: string;
};

function LogInContent() {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleShowPasswordClick = () => setShowPassword(!showPassword);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<FormValues>();

  const { mutateAsync } = useMutation(async (data: FormValues) => {
    const res = await axios.post("/users/log_in", {
      user: data,
    });

    if (res.data.userId) {
      window.location.assign("/user_app/home");
    } else {
      const error = res.data.error;
      if (error.key === "incorrect_password") {
        setError("password", {
          type: "server",
          message: error.message,
        });
      } else if (error.key === "non_existing_email") {
        setError("email", {
          type: "server",
          message: error.message,
        });
      } else {
        setError("email", {
          type: "server",
          message: error.message,
        });
      }
    }
  });

  return (
    <>
      <StyledCard>
        <CardHeader textAlign="center">
          <Heading size="lg">Log In</Heading>
        </CardHeader>
        <CardBody>
          <StyledText>All fields are required.</StyledText>

          <Form onSubmit={handleSubmit((data) => mutateAsync(data))}>
            <StyledFormControl isRequired isInvalid={!!errors.email}>
              <FormLabel htmlFor="email" requiredIndicator={<StyledAsterisk />}>
                Email address
              </FormLabel>
              <Input
                id="email"
                type="text"
                {...register("email", {
                  required: {
                    value: true,
                    message: "Error: Email is required",
                  },
                  validate: (v) => isValidEmail(v) || "Email is not valid",
                })}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message?.toString()}
              </FormErrorMessage>
            </StyledFormControl>

            <StyledFormControl isRequired isInvalid={!!errors.password}>
              <FormLabel
                htmlFor="password"
                requiredIndicator={<StyledAsterisk />}
              >
                Password
              </FormLabel>
              <InputGroup size="md">
                <Input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  {...register("password", {
                    required: {
                      value: true,
                      message: "Error: Password is required",
                    },
                  })}
                  paddingRight="70px" // Cut off text for the right button
                />
                <InputRightElement width="4em">
                  <StyledPasswordViewButton
                    variant="outline"
                    size="sm"
                    onClick={handleShowPasswordClick}
                  >
                    {showPassword ? "Hide" : "Show"}
                  </StyledPasswordViewButton>
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>
                {errors.password && errors.password.message?.toString()}
              </FormErrorMessage>
            </StyledFormControl>

            <StyledButton
              isLoading={isSubmitting}
              type="submit"
              data-umami-event="login-submit-btn"
            >
              Log In
            </StyledButton>
            <Box marginTop="1em">
              <Link
                as={ReactRouterLink}
                to={"/users/password/new"}
                data-umami-event="forgot-password-link"
              >
                Forgot your password?
              </Link>
            </Box>
          </Form>
        </CardBody>
      </StyledCard>
    </>
  );
}

export default LogInContent;
