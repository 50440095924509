import {
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Skeleton,
  chakra,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { useNavigate, useParams } from "react-router";

import PreviousRetrosPanel from "../previous_retros_panel";
import RetroTemplate from "../retro_template";

export type RetroSpaceType = {
  id: number;
  name: string;
  retroSpaceMembers: Array<{
    id: number;
    firstName: string;
    lastName: string;
  }>;
};

// const StyledSecondaryHeading = chakra(Box, {
//   baseStyle: {
//     display: "flex",
//     justifyContent: "space-between",
//     marginTop: "2em",
//     marginBottom: "4em",
//     gap: "5em",
//   },
// });

const StyledCard = chakra(Card, {
  baseStyle: {
    marginTop: "2em",
    marginBottom: "4em",
    backgroundColor: "core.apple",
    paddingTop: "0em",
    width: "95%",
  },
});

// const StyledLink = chakra(Link, { baseStyle: { textDecoration: "underline" } });

export default function RetroSpaceTemplate() {
  let navigate = useNavigate();

  const navigateToCreateRetro = () => {
    let path = `retros/new_retro`;
    navigate(path);
  };

  const { retro_space_id: retroSpaceId } = useParams();

  const {
    isLoading: retroSpaceIsLoading,
    data: retroSpaceData,
    isError: isRetroSpaceError,
  } = useQuery({
    queryKey: ["retroSpace", retroSpaceId],
    queryFn: (): Promise<RetroSpaceType> =>
      axios
        .get(`/api/user_app/retro_spaces/${retroSpaceId}`)
        .then((res) => res.data),
  });

  if (isRetroSpaceError) {
    return <div>Uhoh, looks like something went wrong...</div>;
  }

  const retroSpaceMembersCount = retroSpaceData?.retroSpaceMembers.length;

  return (
    <Skeleton isLoaded={!retroSpaceIsLoading}>
      <StyledCard>
        <CardHeader textAlign="center">
          <Heading size="md">Preview a Recap!</Heading>
          <CardBody>
            Recap creation is unavailable during this initial phase of beta
            testing, but here is a preview of what it will look like.
          </CardBody>
        </CardHeader>
      </StyledCard>
      <Heading as="h1" fontSize="2em" marginBottom="0.5em">
        {retroSpaceData?.name} Hive
      </Heading>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" flexFlow="row wrap" marginTop="2em">
          {retroSpaceData?.retroSpaceMembers.map((member) => {
            return (
              <Avatar
                margin="0em 0.5em 0.5em 0em"
                key={member.id}
                size={"md"}
                name={`${member.firstName} ${member.lastName}`}
              />
            );
          })}
        </Box>
        {retroSpaceData?.retroSpaceMembers &&
          (retroSpaceMembersCount ?? 0) > 1 && (
            <Button variant="solid" onClick={navigateToCreateRetro}>
              Create Recap
            </Button>
          )}
      </Box>

      {/* // TODO: Re-comment back in post-BETA
      <StyledSecondaryHeading>
        <p>
          You will be able to see your partner's entries to the Current Recap 1
          day before your recap meeting.
        </p>
        {retroSpaceMembersCount && retroSpaceMembersCount < 2 ? (
          <StyledLink>Invite Partner to this Hive</StyledLink>
        ) : (
          <StyledLink>Add Hive Member</StyledLink>
        )}
      </StyledSecondaryHeading>
      {retroSpaceMembersCount && retroSpaceMembersCount < 2 ? (
        <StyledCard>
          <CardBody>
            Here's a preview of a recap. To start using this, invite your
            partner to this hive.
          </CardBody>
        </StyledCard>
      ) : null} */}
      <Box display="flex" flexFlow="row wrap" gap="15em" marginTop="3em">
        <RetroTemplate retroSpaceMembersCount={retroSpaceMembersCount ?? 0} />
        <PreviousRetrosPanel />
      </Box>
    </Skeleton>
  );
}
