import React from "react";
import { Navigate, Outlet } from "react-router-dom";

// A Route that is only active if a condition is satisfied, often used with
// user authentication, otherwise it will route to redirectPath.
export default function ProtectedRoute({
  isActive = false,
  redirectPath = "/",
  children,
}: {
  isActive: boolean;
  redirectPath: string;
  children?: React.ReactNode;
}) {
  if (!isActive) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? <>{children}</> : <Outlet />;
}
