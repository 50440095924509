import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Skeleton,
  Stack,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import InfoIcon from "@mui/icons-material/Info";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";

import Form from "../../library/ui_primitives/form";
import { UserContext } from "../app";
import { RetroSpaceType } from "../components/retro_space_template";

type RetroFormValues = {
  // NOTE: Ideally this would be an array of ID numbers, but its causing issues with chakra-ui.
  retroMemberIds: Array<string>;
};

export default function CreateRetro() {
  const toast = useToast();
  const userProfile = useContext(UserContext);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<RetroFormValues>({
    defaultValues: {
      retroMemberIds: [],
    },
  });

  const { retro_space_id: retroSpaceId } = useParams();

  const { isLoading: retroSpaceIsLoading, data: retroSpaceData } = useQuery({
    queryKey: ["retroSpace", retroSpaceId],
    queryFn: (): Promise<RetroSpaceType> =>
      axios
        .get(`/api/user_app/retro_spaces/${retroSpaceId}`)
        .then((res) => res.data),
  });
  let navigate = useNavigate();

  const { mutateAsync } = useMutation(async (data: RetroFormValues) => {
    const res = await axios.post("/api/user_app/retros/create", {
      ...data,
      retroSpaceId: retroSpaceId,
    });

    if (res.data.retroId) {
      toast({
        title: "Retro created",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      const retroId = res.data.retroId;
      let path = `/user_app/retro_spaces/${retroSpaceId}/retros/${retroId}`;
      navigate(path);
    } else {
      toast({
        title: res.data.errors.join(","),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  });

  const otherRetroSpaceMembers = retroSpaceData?.retroSpaceMembers.filter(
    (member) => member.id !== userProfile?.userId
  );

  return (
    <Skeleton isLoaded={!retroSpaceIsLoading}>
      <Form onSubmit={handleSubmit(mutateAsync)}>
        {/* // TODO: Insert breadcrumbs component: (https://chakra-ui.com/docs/components/breadcrumb) */}
        <Heading as="h1" fontSize="30px" marginBottom="20px">
          {retroSpaceData?.name} Family Space: Create Retro
        </Heading>

        {
          <FormControl isInvalid={!!errors.retroMemberIds}>
            <Box display="flex" marginTop="30px">
              <FormLabel htmlFor="addMembersToRetro">
                (optional) Select other member(s) in this Family Space who can
                view/interact with this retro
              </FormLabel>
              <Tooltip label="You can always add more members to this retro later on!">
                <InfoIcon />
              </Tooltip>
            </Box>

            <CheckboxGroup>
              <Stack id="addMembersToRetro">
                {otherRetroSpaceMembers?.map((member) => {
                  return (
                    <Checkbox
                      value={member.id.toString()}
                      key={member.id}
                      {...register("retroMemberIds")}
                    >
                      {member.firstName} {member.lastName}
                    </Checkbox>
                  );
                })}
              </Stack>
            </CheckboxGroup>
            <FormErrorMessage>
              {errors.retroMemberIds &&
                errors.retroMemberIds.message?.toString()}
            </FormErrorMessage>
          </FormControl>
        }

        <Button marginTop="50px" isLoading={isSubmitting} type="submit">
          Create Retro
        </Button>
      </Form>
    </Skeleton>
  );
}
