import React from "react";

type FormProps = React.FormHTMLAttributes<HTMLFormElement>;

const Form: React.FC<FormProps> = ({ ...props }) => {
  return (
    // noValidate: Disable automatic HTML5 browser validation
    // since we will do our own validations.
    <form noValidate={true} {...props}>
      {props.children}
    </form>
  );
};

export default Form;
