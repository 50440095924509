import {
  Box,
  BoxProps,
  CloseButton,
  Flex,
  Icon,
  Image,
  Link,
  useDisclosure,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { Link as ReactRouterLink, useLocation } from "react-router-dom";

import { useIsMobile } from "../../user_app/hooks/use_is_mobile";

export interface LinkItemProps {
  name: string;
  icon: any;
  path: string;
}

export default function Sidebar({
  sidebarLinkItems,
  children,
  onSidebarToggle,
}: {
  sidebarLinkItems: Array<LinkItemProps>;
  children: ReactNode;
  onSidebarToggle: () => void;
}) {
  const { onToggle } = useDisclosure();
  const isMobile = useIsMobile();

  return (
    <>
      {isMobile ? (
        <>
          <SidebarMobileContent
            sidebarLinkItems={sidebarLinkItems}
            onToggle={onSidebarToggle}
          />
          {children}
        </>
      ) : (
        <Box display={"flex"} flexDirection={"row"}>
          <SidebarContent sidebarLinkItems={sidebarLinkItems} />
          {children}
        </Box>
      )}
    </>
  );
}

interface SidebarProps extends BoxProps {
  sidebarLinkItems: Array<LinkItemProps>;
  onToggle?: () => void;
}

const SidebarContent = ({ sidebarLinkItems, ...rest }: SidebarProps) => {
  return (
    <Box
      transition="3s ease"
      borderRight="1px"
      borderRightColor="core.chocolate"
      minWidth={"20%"}
      bg="darkMoss.500"
      {...rest}
    >
      <Box marginTop="1em" padding="0.5em">
        {sidebarLinkItems.map((linkItem) => (
          <NavItem
            key={linkItem.name}
            icon={linkItem.icon}
            path={linkItem.path}
          >
            {linkItem.name}
          </NavItem>
        ))}
      </Box>
    </Box>
  );
};

const SidebarMobileContent = ({
  sidebarLinkItems,
  onToggle,
  ...rest
}: SidebarProps) => {
  return (
    <Box
      transition="3s ease"
      borderRightColor="core.chocolate"
      bg="darkMoss.500"
      position={"fixed"}
      width={"100%"}
      height={"100%"}
      zIndex={100}
      {...rest}
    >
      <Box marginTop="1em" padding="0.5em">
        {sidebarLinkItems.map((linkItem) => (
          <NavItem
            key={linkItem.name}
            icon={linkItem.icon}
            path={linkItem.path}
            onToggle={onToggle}
          >
            {linkItem.name}
          </NavItem>
        ))}
      </Box>
    </Box>
  );
};

const NavItem = ({
  icon,
  path,
  children,
  onToggle,
}: {
  icon: any;
  path: string;
  children: string | number;
  onToggle?: () => void;
}) => {
  const location = useLocation();

  return (
    <Link
      as={ReactRouterLink}
      to={path}
      color="white"
      _focus={{
        fontWeight: "bold",
        textDecoration: "underline 0.3em",
      }}
      onClick={onToggle}
    >
      <Flex
        align="center"
        p="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "core.apple",
          color: "gray.900",
        }}
        transition={"0.15s"}
        sx={{
          "&.active": {
            bg: "core.apple",
            color: "gray.900",
            fontWeight: "bold",
          },
        }}
        className={location.pathname?.includes(path) ? "active" : ""}
      >
        {icon && (
          <Icon
            mr="2"
            fontSize="24"
            _groupHover={{
              color: "gray.900",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};
