export function isValidEmail(email: string): boolean {
  const strippedEmail = email.trim();
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(strippedEmail);
}

/**
 * A regular expression to validate passwords.
 * Make sure FE and BE matches the regex.
 *
 * The password must meet the following criteria:
 * - At least one lowercase letter
 * - At least one uppercase letter
 * - At least one digit
 * - At least one special character from the set !@#$%^&*()_+-=[]{};':"\\|,.<>/?
 * - Minimum length of 8 characters
 */
export const passwordRegex: RegExp =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,}$/;

export const textWithoutHtmlTags = (rawText: string) => {
  return rawText.replace(/<[^>]*>?/gm, "");
};

export const textWithoutHtmlTagsCharCount = (rawText: string) => {
  return textWithoutHtmlTags(rawText).length;
};
