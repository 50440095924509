import { Button, Card, CardBody, chakra } from "@chakra-ui/react";
import React from "react";

type ReviewPreviousActionItemsContainerType = {
  retroSpaceMembersCount: number;
};

const StyledCard = chakra(Card, {
  baseStyle: {
    marginTop: "2em",
    marginBottom: "2em",
    backgroundColor: "core.apple",
    paddingTop: "0em",
    width: "100%",
    marginRight: "1em",
  },
});

const StyledCardBody = chakra(CardBody, {
  baseStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export default function ReviewPreviousActionItemsContainer({
  retroSpaceMembersCount,
}: ReviewPreviousActionItemsContainerType) {
  return (
    <div>
      <StyledCard>
        <StyledCardBody>
          <p>Review previous recap's next steps</p>
          <Button
            variant="outline"
            isDisabled={retroSpaceMembersCount < 2 ? true : false}
          >
            View
          </Button>
        </StyledCardBody>
      </StyledCard>
    </div>
  );
}
