import {
  Box,
  Button,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  chakra,
  useToast,
} from "@chakra-ui/react";
import { Check } from "@mui/icons-material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { useSearchParams } from "react-router-dom";

import { ActionItemFormValues } from "./create_action_item";

const StyledTable = chakra(Table, {
  baseStyle: {
    th: {
      borderColor: "darkMoss.50",
      backgroundColor: "darkMoss.400",
      color: "white",
    },
    td: {
      borderColor: "darkMoss.50",
    },
  },
});

export type ActionItemType = {
  id: number;
  assigneeId: number;
  assigneeFirstName: string;
  assigneeLastName: string;
  description: string;
  isComplete: boolean;
  // retroSpaceUsers: Array<{
  //   id: number;
  //   firstName: string;
  //   lastName: string;
  // }>;
};

export type ActionItemGroup = ActionItemType[];

export default function ActionItemTable({
  actionItems,
  onDeleteClicked,
  refetch = () => {},
}: {
  actionItems?: ActionItemType[];
  onDeleteClicked: (id: number) => void;
  refetch?: () => void;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const toast = useToast();
  const actionItemId = searchParams.get("actionItem_id");

  const { mutateAsync } = useMutation(
    async (data: Partial<ActionItemFormValues> & { actionItemId: number }) => {
      const res = await axios.put(
        `/api/user_app/action_items/${actionItemId}/update`,
        {
          ...data,
          actionItemId: data.actionItemId,
        }
      );

      if (res.data.actionItemId) {
        toast({
          title: "Success: Great job completing this action item! 🎉",
          status: "success",
          isClosable: true,
        });
        setSearchParams({});
      } else {
        toast({
          title: res.data.errors.join(","),
          status: "error",
          isClosable: true,
        });
      }

      refetch();
    }
  );

  return (
    <TableContainer backgroundColor="core.apple" borderRadius="1em">
      <StyledTable aria-label="Table of remaining action items">
        <Thead>
          <Tr>
            <Th>Mark Complete</Th>
            <Th>Item</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {actionItems?.map((individualActionItem) => {
            return (
              <Tr key={individualActionItem.id}>
                <Td>
                  <IconButton
                    borderRadius="2em"
                    aria-label={`mark action item ${individualActionItem.description} complete`}
                    variant="outline"
                    icon={<Check />}
                    data-umami-event="mark-action-item-complete-btn"
                    onClick={() => {
                      mutateAsync({
                        isComplete: !individualActionItem.isComplete,
                        actionItemId: individualActionItem.id,
                      });
                    }}
                  />
                </Td>
                <Td>{individualActionItem.description}</Td>
                <Td>
                  <Box display="flex" gap="1em">
                    <Button
                      data-umami-event="view-edit-action-item-btn"
                      onClick={() => {
                        searchParams.set(
                          "action_item_id",
                          `${individualActionItem.id}`
                        );
                        setSearchParams(searchParams);
                      }}
                    >
                      View/Edit
                    </Button>
                    <Button
                      variant="outline"
                      data-umami-event="delete-action-item-btn"
                      onClick={() => {
                        onDeleteClicked(individualActionItem.id);
                      }}
                    >
                      Delete
                    </Button>
                  </Box>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
        <Tfoot>
          <Tr>
            <Th>Mark Complete</Th>
            <Th>Item</Th>
            <Th>Actions</Th>
          </Tr>
        </Tfoot>
      </StyledTable>
    </TableContainer>
  );
}
