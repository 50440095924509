import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  chakra,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

import Form from "../../../library/ui_primitives/form";
import Input from "../../../library/ui_primitives/input";
import { UserContext } from "../../app";
import { ActionItemType } from "./action_item_table";
import { ActionItemFormValues } from "./create_action_item";

const EditModalButton = chakra(Button, {
  baseStyle: {
    marginTop: "1.5em",
    marginBottom: "1.5em",
  },
});

export default function EditActionItemModal() {
  const toast = useToast();
  const queryClient = useQueryClient();
  const userProfile = useContext(UserContext);
  const { retro_space_id: retroSpaceId } = useParams();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();
  const actionItemId = searchParams.get("action_item_id");

  // The presence of the modal will be driven by if the actionItemId search param exists.
  useEffect(() => {
    if (actionItemId) {
      onOpen();
    } else {
      onClose();
    }
  }, [actionItemId]);

  const { isLoading: actionItemIsLoading, data: actionItemData } = useQuery({
    queryKey: ["actionItem", actionItemId],
    queryFn: (): Promise<ActionItemType> =>
      axios
        .get(`/api/user_app/action_items/${actionItemId}`)
        .then((res) => res.data),
    enabled: !!actionItemId, // Only run this query if actionItemId exists.
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<ActionItemFormValues>();

  // Since actionItemData is initially null when the modal loads,
  // reset the default values when it does load.
  useEffect(() => {
    reset(actionItemData);
  }, [actionItemData]);

  const currentRetroSpace = userProfile?.retroSpaces.find(
    (fs) => fs.id === parseInt(retroSpaceId!)
  );

  const isFormEdited = isDirty;

  const { mutateAsync } = useMutation(async (data: ActionItemFormValues) => {
    const res = await axios.put(
      `/api/user_app/action_items/${actionItemId}/update`,
      {
        ...data,
        actionItemId: actionItemId,
      }
    );

    if (res.data.actionItemId) {
      toast({
        title: "Success: Action item updated! 🎉",
        status: "success",
        isClosable: true,
      });
      setSearchParams({});
      queryClient.invalidateQueries({ queryKey: ["retro"] });
      queryClient.invalidateQueries({ queryKey: ["actionItems"] });
    } else {
      toast({
        title: res.data.errors.join(","),
        status: "error",
        isClosable: true,
      });
    }
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setSearchParams({});
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <Skeleton isLoaded={!actionItemIsLoading}>
          <ModalHeader>View/Edit Action Item</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Form onSubmit={handleSubmit((data) => mutateAsync(data))}>
              {/* {currentRetroSpace && (
                <FormControl isRequired isInvalid={!!errors.assigneeId}>
                  <StyledFieldPadding>
                    <FormLabel htmlFor="assigneeId">ActionItem Owner</FormLabel>
                  </StyledFieldPadding>
                  <Select
                    placeholder="Select option"
                    id="assigneeId"
                    {...register("assigneeId", {
                      valueAsNumber: true,
                      required: "Error: ActionItem Owner is required",
                    })}
                  >
                    {actionItemData?.retroUsers.map((member) => {
                      return (
                        <option key={member.id} value={member.id}>
                          {member.firstName} {member.lastName}
                        </option>
                      );
                    })}
                  </Select>
                  <FormErrorMessage>
                    {errors.assigneeId && errors.assigneeId.message?.toString()}
                  </FormErrorMessage>
                </FormControl>
              )} */}

              <FormControl isInvalid={!!errors.description}>
                <Box marginTop="1em">
                  <FormLabel htmlFor="description">
                    Action Item Description
                  </FormLabel>
                </Box>
                <Input id="description" {...register("description")} />
                <FormErrorMessage>
                  {errors.description && errors.description.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              <Box display="flex" justifyContent="flex-end">
                {isFormEdited ? (
                  <EditModalButton
                    mr={3}
                    isLoading={isSubmitting}
                    type="submit"
                  >
                    Save Changes
                  </EditModalButton>
                ) : (
                  <EditModalButton mr={3} isDisabled={true}>
                    Save Changes
                  </EditModalButton>
                )}
                <EditModalButton
                  variant="ghost"
                  onClick={() => {
                    setSearchParams({});
                    onClose();
                  }}
                >
                  Cancel
                </EditModalButton>
              </Box>
            </Form>
          </ModalBody>
        </Skeleton>
      </ModalContent>
    </Modal>
  );
}
