import { Box, ChakraProvider, chakra } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import axios from "axios";
import React from "react";
import { createRoot } from "react-dom/client";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";

import ProtectedRoute from "../components/library/other/protected_route";
import GlobalFooter from "../components/library/ui_custom/global_footer";
import CreateAccountContent from "../components/signed_out/create_account_content";
import ForgetPasswordContent from "../components/signed_out/forget_password_content";
import SignedOutLayout from "../components/signed_out/layout";
import LogInContent from "../components/signed_out/log_in_content";
import ResetPasswordContent from "../components/signed_out/reset_password_content";
import App from "../components/user_app/app";
import theme from "../themes/theme";

Sentry.init({
  dsn: "https://34f710e2120058544de7ac380b66daf3@o4507821542146048.ingest.us.sentry.io/4508093345628160",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // TODO: Temporarily commenting out the following line in case it causes issues with the app
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.NODE_ENV,
});

const rootElement = document.getElementById("root");
const root = createRoot(rootElement!);

// Extract any attributes passed from backend.
const userId = rootElement?.dataset.userId || "";

const csrfToken = document
  ?.querySelector('meta[name="csrf-token"]')
  ?.getAttribute("content");

// Set axios defaults.
axios.defaults.headers.common["Key-Inflection"] = "camel";
axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ChakraProvider
        theme={theme}
        toastOptions={{ defaultOptions: { duration: 8000, isClosable: true } }}
      >
        <BrowserRouter>
          <Routes>
            <Route
              path="/user_app/*"
              element={
                <ProtectedRoute
                  isActive={!!userId}
                  // TODO: THIS REDIRECT DOESNT link back to root...
                  redirectPath={"/users/log_in"}
                >
                  <App />
                </ProtectedRoute>
              }
            />

            {/* For now redirect to log in on root, do we need this? */}
            {/* <Route path="/" element={<Navigate to="/users/log_in" />} /> */}

            <Route path="/users/log_in/*" element={<SignedOutLayout />}>
              <Route path="" element={<LogInContent />} />
            </Route>

            <Route path="/users/create_account/*" element={<SignedOutLayout />}>
              <Route path="" element={<CreateAccountContent />} />
            </Route>

            <Route path="/users/password/new/*" element={<SignedOutLayout />}>
              <Route path="" element={<ForgetPasswordContent />} />
            </Route>

            <Route path="/users/password/edit/*" element={<SignedOutLayout />}>
              <Route path="" element={<ResetPasswordContent />} />
            </Route>

            {/* No matches to a route, redirect to root */}
            {/* <Route path="*" element={<Navigate to="/" />} /> */}
          </Routes>
        </BrowserRouter>
      </ChakraProvider>
      <GlobalFooter />
      <ReactQueryDevtools />
    </QueryClientProvider>
  </React.StrictMode>
);
