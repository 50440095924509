import { useEffect, useRef, useState } from "react";

import { textWithoutHtmlTagsCharCount } from "../../../utility/string_utils";

// Minimum number of new characters to trigger the companion
const CHAR_THRESHOLD = 300;

// Minimum number of seconds of inactivity to trigger the companion
const DOWNTIME_THRESHOLD = 5;

const useCompanionDriver = ({
  onCompanionTrigger,
}: {
  onCompanionTrigger: () => void;
}) => {
  const [currentCharCount, setCurrentCharCount] = useState<number>(0);
  const [lastCharCount, setLastCharCount] = useState<number>(0);
  const [timePassed, setTimePassed] = useState<number>(0);

  const [charConditionMet, setCharConditionMet] = useState<boolean>(false);
  const [timeConditionMet, setTimeConditionMet] = useState<boolean>(false);

  const intervalRef = useRef<NodeJS.Timeout | undefined>();

  const setInputText = (inputText: string) => {
    setCurrentCharCount(textWithoutHtmlTagsCharCount(inputText));
  };

  useEffect(() => {
    if (currentCharCount - lastCharCount >= CHAR_THRESHOLD) {
      setCharConditionMet(true);
    }

    // Every time we type, reset the timer.
    clearInterval(intervalRef.current);
    setTimePassed(0);
  }, [currentCharCount, intervalRef]);

  useEffect(() => {
    if (!charConditionMet) {
      return;
    }

    if (timePassed >= DOWNTIME_THRESHOLD) {
      setTimeConditionMet(true);
      return;
    }

    intervalRef.current = setInterval(() => {
      setTimePassed(timePassed + 1);
    }, 1000);

    return () => clearInterval(intervalRef.current);
  }, [
    timePassed,
    charConditionMet,
    currentCharCount, // Every time we type, run this effect.
  ]);

  useEffect(() => {
    if (charConditionMet && timeConditionMet) {
      onCompanionTrigger();

      setLastCharCount(currentCharCount);
      setCharConditionMet(false);
      setTimeConditionMet(false);
    }
  }, [charConditionMet, timeConditionMet]);

  return [setInputText];
};

export default useCompanionDriver;
