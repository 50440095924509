import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const useCanUseAiFeatures = () => {
  const { isLoading, data, refetch } = useQuery({
    queryKey: ["can_use_ai_features"],
    queryFn: (): Promise<{ canUseAiFeatures: boolean }> =>
      axios
        .get("/api/user_app/profile/can_use_ai_features")
        .then((res) => res.data),
  });

  return {
    isLoading,
    canUseAiFeatures: data?.canUseAiFeatures,
    refetch,
  };
};

export default useCanUseAiFeatures;
