import { formErrorAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(formErrorAnatomy.keys);

const baseStyle = definePartsStyle({
  text: {
    color: "darkRed",
  },
});

// export the base styles in the component theme
export const formErrorTheme = defineMultiStyleConfig({ baseStyle });
