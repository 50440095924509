import {
  CardBody,
  CardHeader,
  FormErrorMessage,
  FormLabel,
  Heading,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";

import { isValidEmail } from "../../utility/string_utils";
import StyledAsterisk from "../library/ui_custom/styled_asterisk";
import Form from "../library/ui_primitives/form";
import Input from "../library/ui_primitives/input";
import {
  StyledButton,
  StyledCard,
  StyledFormControl,
  StyledText,
} from "./shared_components";

type FormValues = {
  email: string;
};

function ForgetPasswordContent() {
  const toast = useToast();
  const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState(false);
  const [buttonText, setButtonText] = React.useState("Send link to email");

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>();

  const handleResetPasswordEmail = async (data: FormValues) => {
    await axios.post("/users/password", { user: data });
    toast({
      title:
        "If your email matches our records, an email will be sent. Follow the instructions to reset your password.",
      status: "success",
    });
    setSubmitButtonDisabled(true);
    setButtonText("Reset email sent");
  };

  return (
    <StyledCard>
      <CardHeader textAlign="center">
        <Heading size="lg">Password Reset</Heading>
      </CardHeader>
      <CardBody>
        <StyledText>
          Enter your email and we'll send you a link to reset your password
        </StyledText>
        <Form onSubmit={handleSubmit((data) => handleResetPasswordEmail(data))}>
          <StyledFormControl isRequired isInvalid={!!errors.email}>
            <FormLabel htmlFor="email" requiredIndicator={<StyledAsterisk />}>
              Email
            </FormLabel>{" "}
            <Input
              id="email"
              type="text"
              {...register("email", {
                required: {
                  value: true,
                  message: "Error: Email is required",
                },
                validate: (v) => isValidEmail(v) || "Email is not valid",
              })}
            />
            <FormErrorMessage>
              {errors.email && errors.email.message?.toString()}
            </FormErrorMessage>
          </StyledFormControl>
          <StyledButton
            isLoading={isSubmitting}
            isDisabled={submitButtonDisabled}
            type="submit"
          >
            {buttonText}
          </StyledButton>
        </Form>
      </CardBody>
    </StyledCard>
  );
}

export default ForgetPasswordContent;
