import { Avatar, Box, Button, Link, Skeleton, chakra } from "@chakra-ui/react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ArchiveIcon from "@mui/icons-material/Archive";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { useNavigate, useParams } from "react-router";

import EditActionItemModal from "../components/action_items/edit_action_item_modal";

export type RetroType = {
  id: number;
  retroSpaceId: number;
  actionItems: Array<{
    id: number;
    assigneeId: number;
    assigneeFirstName: string;
    assigneeLastName: string;
    dueDate: string;
  }>;
  retroUsers: Array<{
    id: number;
    firstName: string;
    lastName: string;
  }>;
};

const StyledAvatar = chakra(Avatar, {
  baseStyle: { margin: "0px 10px 10px 0px" },
});

const StyledAvatarContainer = chakra("div", {
  baseStyle: {
    display: "flex",
    flexFlow: "row wrap",
    marginTop: "30px",
  },
});

export default function Retro() {
  let navigate = useNavigate();
  const navigateToCreateActionItem = () => {
    let path = `new_actionItem`;
    navigate(path);
  };

  const { retro_space_id: retroSpaceId, retro_id: retroId } = useParams();

  const {
    isLoading: retroIsLoading,
    data: retroData,
    isError: isRetroError,
  } = useQuery({
    queryKey: ["retro", retroId],
    queryFn: (): Promise<RetroType> =>
      axios
        .get(`/api/user_app/retro_spaces/${retroSpaceId}/retros/${retroId}`)
        .then((res) => res.data),
  });

  if (isRetroError) {
    return <div>Uhoh, looks like something went wrong...</div>;
  }

  return (
    <Skeleton isLoaded={!retroIsLoading}>
      <EditActionItemModal />
      {/* // TODO: Insert breadcrumbs component: (https://chakra-ui.com/docs/components/breadcrumb) */}

      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Button variant="solid" onClick={navigateToCreateActionItem}>
          <AddBoxIcon />
          Add ActionItem
        </Button>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <StyledAvatarContainer>
          {retroData?.retroUsers.map((member) => {
            return (
              <StyledAvatar
                key={member.id}
                size={"md"}
                name={`${member.firstName} ${member.lastName}`}
              />
            );
          })}
        </StyledAvatarContainer>
        <Button variant="outline">
          <ArchiveIcon />
          Archive Retro
        </Button>
      </Box>
      {/* // TODO: Hook up Add Retro Member CTA */}
      <Box display="flex" justifyContent="flex-end">
        <Link>Add Retro Member</Link>
      </Box>
    </Skeleton>
  );
}
