import { Box, Image } from "@chakra-ui/react";
import React from "react";

import clari_mascot_sad from "../../../../assets/images/clari_mascot_sad.png";

export default function WarningNotificationBanner({
  warningText,
}: {
  warningText: string;
}) {
  return (
    <Box
      bg="warning"
      p={4}
      borderRadius="md"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      marginBottom="1.5em"
    >
      <Image
        height="2em"
        marginRight="0.5em"
        src={clari_mascot_sad}
        alt="Clari the mascot (sad version)"
      />
      {warningText}
    </Box>
  );
}
