import {
  FormControl,
  FormLabel,
  Heading,
  Select,
  chakra,
} from "@chakra-ui/react";
import React from "react";

const StyledFormControl = chakra(FormControl, {
  baseStyle: { marginTop: "1em" },
});

const StyledFormLabel = chakra(FormLabel, {
  baseStyle: { marginBottom: "0em" },
});

export default function PreviousRetrosPanel() {
  return (
    <div>
      <Heading as="h2" size="md">
        Previous Recaps Panel
      </Heading>
      <StyledFormControl>
        <StyledFormLabel htmlFor="sort-by">Sort by:</StyledFormLabel>
        <Select id="sort-by" size="xs" bg="white" disabled={true}>
          <option value="newest">Most Recent (default)</option>
          <option value="oldest">Oldest</option>
        </Select>
      </StyledFormControl>
    </div>
  );
}
