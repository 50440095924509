import { IconButton, chakra } from "@chakra-ui/react";
import {
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  StrikethroughS,
} from "@mui/icons-material";
import { Editor, EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React, { useEffect } from "react";

const StyledIconButton = chakra(IconButton, {
  baseStyle: {
    marginRight: "0.5em",
  },
});

const StyledMenuBarContainer = chakra("div", {
  baseStyle: {
    backgroundColor: "darkMoss.500",
    borderRadius: "0.2em",
  },
});

const StyledEditorContent = chakra(EditorContent, {
  baseStyle: {
    backgroundColor: "white",
    flex: 1,
    borderRadius: "0.5em",
    ".initialEditableContainer": {
      overflowY: "scroll",
      padding: "1em",
      border: "1px solid #E2E8F0",
      height: "26em",
    },
    ".editable-content": {
      overflowY: "scroll",
      padding: "1em",
      border: "1px solid #E2E8F0",
      height: "15em",
    },

    // This styling is to ensure the (un)ordered lists are not offset from the left
    ".tiptap": {
      ":first-child": {
        marginTop: 0,
      },
      "ul, ol": {
        padding: "0 1rem",
        margin: "1.25rem 1rem 1.25rem 0.4rem",
        "li p": {
          marginTop: "0.25em",
          marginBottom: "0.25em",
        },
      },
    },
  },
});

const MenuBar = ({ editor }: { editor: Editor | null }) => {
  if (!editor) {
    return null;
  }

  return (
    <>
      <StyledMenuBarContainer>
        <StyledIconButton
          aria-label="Format bold"
          icon={<FormatBold />}
          size="xs"
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={editor.isActive("bold") ? "is-active" : ""}
          data-umami-event="format-bold-text"
        />
        <StyledIconButton
          aria-label="Format italic"
          icon={<FormatItalic />}
          size="xs"
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={editor.isActive("italic") ? "is-active" : ""}
          data-umami-event="format-italic-text"
        />
        <StyledIconButton
          aria-label="Format strikethrough"
          icon={<StrikethroughS />}
          size="xs"
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          className={editor.isActive("strike") ? "is-active" : ""}
          data-umami-event="format-strikethrough-text"
        />
        <StyledIconButton
          aria-label="Format bullet list"
          icon={<FormatListBulleted />}
          size="xs"
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive("bulletList") ? "is-active" : ""}
          data-umami-event="format-unordered-list"
        />
        <StyledIconButton
          aria-label="Format ordered list"
          icon={<FormatListNumbered />}
          size="xs"
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive("orderedList") ? "is-active" : ""}
          data-umami-event="format-ordered-list"
        />
      </StyledMenuBarContainer>
    </>
  );
};

export default function RichTextArea({
  editable = true,
  initialEditableContainer = false,
  onChange,
  value,
  isDirty,
}: {
  editable?: boolean;
  initialEditableContainer?: boolean;
  onChange?: (...event: any[]) => void;
  value: string;
  isDirty?: boolean;
}) {
  const editor = useEditor({
    extensions: [StarterKit],
    content: value,
    onUpdate({ editor }) {
      onChange && onChange(editor.getHTML());
    },
    editable: editable,
    editorProps: {
      attributes: {
        class: initialEditableContainer
          ? "initialEditableContainer"
          : editable
          ? "editable-content"
          : "read-only-content",
      },
    },
  });

  useEffect(() => {
    if (!isDirty) {
      editor?.commands.clearContent();
    }
  }, [isDirty]);

  useEffect(() => {
    if (!isDirty) {
      editor?.commands.setContent(value);
    }
  }, [value, isDirty]);

  if (!editor) {
    return null;
  }

  return (
    <>
      {(editable || initialEditableContainer) && <MenuBar editor={editor} />}
      <StyledEditorContent editor={editor} />
    </>
  );
}
