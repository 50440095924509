import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Select,
  Skeleton,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";

import Form from "../../../library/ui_primitives/form";
import { UserContext } from "../../app";
import { RetroType } from "../../retros_page";

export type ActionItemFormValues = {
  assigneeId: number;
  description: string;
  isComplete: boolean;
};

export default function CreateActionItem() {
  const toast = useToast();
  const userProfile = useContext(UserContext);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ActionItemFormValues>({
    defaultValues: {
      assigneeId: undefined,
      description: "",
    },
  });

  const { retro_space_id: retroSpaceId, retro_id: retroId } = useParams();

  const currentRetroSpace = userProfile?.retroSpaces.find(
    (fs) => fs.id === parseInt(retroSpaceId!)
  );

  const { isLoading: retroIsLoading, data: retroData } = useQuery({
    queryKey: ["retro", retroId],
    queryFn: (): Promise<RetroType> =>
      axios
        .get(`/api/user_app/retro_spaces/${retroSpaceId}/retros/${retroId}`)
        .then((res) => res.data),
  });
  let navigate = useNavigate();

  const { mutateAsync } = useMutation(async (data: ActionItemFormValues) => {
    const res = await axios.post("/api/user_app/action_items/create", {
      ...data,
      retroId: retroId,
    });

    if (res.data.actionItemId) {
      toast({
        title: "ActionItem created",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      let path = `/user_app/retro_spaces/${retroSpaceId}/retros/${retroId}`;
      navigate(path);
    } else {
      toast({
        title: res.data.errors.join(","),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  });

  return (
    <Skeleton isLoaded={!retroIsLoading}>
      <Form onSubmit={handleSubmit(mutateAsync)}>
        {/* // TODO: Insert breadcrumbs component: (https://chakra-ui.com/docs/components/breadcrumb) */}
        <Heading as="h1" fontSize="2em" marginBottom="1em">
          Add ActionItem
        </Heading>

        {currentRetroSpace && (
          <FormControl isRequired isInvalid={!!errors.assigneeId}>
            <Box display="flex" marginTop="2em">
              <FormLabel htmlFor="assigneeId">ActionItem Owner</FormLabel>
            </Box>
            <Select
              placeholder="Select option"
              id="assigneeId"
              {...register("assigneeId", {
                required: "Error: ActionItem Owner is required",
              })}
            >
              {retroData?.retroUsers.map((member) => {
                return (
                  <option key={member.id} value={member.id}>
                    {member.firstName} {member.lastName}
                  </option>
                );
              })}
            </Select>
            <FormErrorMessage>
              {errors.assigneeId && errors.assigneeId.message?.toString()}
            </FormErrorMessage>
          </FormControl>
        )}

        <FormControl isInvalid={!!errors.description}>
          <Box display="flex" marginTop="2em">
            <FormLabel htmlFor="description">
              (optional) ActionItem Description
            </FormLabel>
          </Box>
          <Textarea id="description" />
          <FormErrorMessage>
            {errors.description && errors.description.message?.toString()}
          </FormErrorMessage>
        </FormControl>

        <Button marginTop="3em" isLoading={isSubmitting} type="submit">
          Create ActionItem
        </Button>
      </Form>
    </Skeleton>
  );
}
