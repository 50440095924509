import {
  Box,
  Card,
  CardHeader,
  Heading,
  IconButton,
  chakra,
} from "@chakra-ui/react";
import { AddBox } from "@mui/icons-material";
import React from "react";

type ReflectionContainerType = {
  category: string;
  retroSpaceMembersCount: number;
};

const StyledCard = chakra(Card, {
  baseStyle: {
    marginTop: "2em",
    marginBottom: "2em",
    backgroundColor: "core.apple",
    paddingTop: "0em",
    width: "100%",
  },
});

export default function ReflectionContainer({
  category,
  retroSpaceMembersCount,
}: ReflectionContainerType) {
  return (
    <div>
      <StyledCard>
        <CardHeader>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Heading size="md">{category}</Heading>
            <IconButton
              aria-label="Add"
              icon={<AddBox />}
              isDisabled={retroSpaceMembersCount < 2 ? true : false}
              onClick={() => {
                // TODO: Opens up modal to add reflection in the respective category
              }}
            />
          </Box>
        </CardHeader>
      </StyledCard>
    </div>
  );
}
