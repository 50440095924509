import React from "react";
import { Outlet } from "react-router-dom";
import TopBar from "../library/ui_custom/top_bar";

function Layout() {
  return (
    <>
      <TopBar />
      <div>
        {/* Outlet renders any child route elements here defined in app.tsx */}
        <Outlet />
      </div>
    </>
  );
}
export default Layout;
